<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="searchQuery"
			search-query-label="Language Name"
			hide-brand-filter
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<TextField
					v-model="languageCodeSearchQuery"
					label="Language Code"
					append-icon="mdi-magnify"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="!readOnlyPermissions"
					@click="addAssignedLanguage"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Default Language
			</template>
			<template #body>
				<ValidationForm #default="{ handleSubmit }">
					<div class="d-flex cassie-horizontal-md">
						<TextField
							v-model="defaultLanguage"
							:disabled="readOnlyPermissions"
							label="Default Language Name *"
							rules="required"
							class="cassie-input-width-md"
						/>
						<PrimaryActionButton
							v-if="!readOnlyPermissions"
							@click="handleSubmit(SubmitDefaultLanguageName)"
						>
							Save
						</PrimaryActionButton>
					</div>
				</ValidationForm>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Additional Languages
			</template>
			<template #body>
				<DataTable
					:items="tableData"
					:headers="tableHeaders"
					@click:row="editAssignedLanguage"
				>
					<template #item.enabledInPublicPortal="{ item }">
						{{ item.enabledInPublicPortal ? 'Yes' : 'No' }}
					</template>
					<template #item.allowPrefPageAutoTranslation="{ item }">
						{{ item.allowPrefPageAutoTranslation ? 'Yes' : 'No' }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							tooltip-text="Open Assigned Language"
							@click="editAssignedLanguage(item)"
						>
							{{ readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil' }}
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ManageLanguageModal
			v-if="assignedLanguage"
			:option-translation-to-edit="assignedLanguage"
			:user-full-permissions="readOnlyPermissions"
			:languages="languageList"
			:is-edit="isEdit"
			@close="assignedLanguage = null"
			@saved="$emit('load-languages')"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import TextField from '../../../../../../shared/components/text-field.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import { LANGUAGES_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { updateDefaultLanguageName } from '../../../../../../shared/utils/api/languages.js'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ManageLanguageModal from './manage-language-modal.vue'
export default {
	components: {
		DataTable,
		TextField,
		PrimaryActionButton,
		SectionCard,
		ValidationForm,
		FilterBar,
		IconButton,
		ManageLanguageModal
	},
	props: {
		tableRecords: {
			type: Array,
			default: () => []
		},
		languages: {
			type: Array,
			default: () => []
		},
		languageList: {
			type: Array,
			default: () => []
		},
		userFullPermissions: Boolean
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			searchQuery: null,
			languageCodeSearchQuery: null,
			showAddModal: false,
			assignedLanguage: null,
			defaultLanguage: '',
			selectedLanguageName: '',
			isEdit: false,
			languageTranslationToEdit: null,
			languageLookupId: ''
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		calculateSuggestions () {
			return this.languages.find(({ languageLookupId }) => languageLookupId === this.assignedLanguage.languageLookupId)
				?.googleLanguageCode ?? ''
		},
		tableData () {
			return this.tableRecords.filter(({ languageName, languageCode }) => {
				let check = true
				if (this.searchQuery) check = languageName.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.languageCodeSearchQuery) check = languageCode?.toLowerCase().includes(this.languageCodeSearchQuery.toLowerCase())
				return check
			})
		},
		tableHeaders () {
			return [
				{ value: 'languageID', text: 'ID', width: '5%' },
				{ value: 'languageName', text: 'Language Name', width: '20%' },
				{ value: 'languageCode', text: 'Language Code', width: '20%' },
				{ value: 'enabledInPublicPortal', text: 'Enabled In Public Portal?', width: '20%' },
				{ value: 'allowPrefPageAutoTranslation', text: 'Allow Auto Translation On Pref Pages?', width: '20%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		},
		usedLanguageCodes () {
			return this.assignedLanguages
				.filter(({ languageID }) => languageID !== this.assignedLanguage?.languageID)
				.map(({ languageCode }) => languageCode)
		},
		readOnlyPermissions () {
			return !this.productAreaPermission(LANGUAGES_MODULE_FULL_PERMISSIONS)
		},
		optionLabelTranslationsTableHeaders () {
			const translationTableHeaders = [
				{ value: 'languageName', text: 'Language', width: '15%' },
				{ value: 'isTranslated', text: 'Translated Status', width: '15%' },
				{ value: 'optInLabelTranslation', text: 'Opt In Label', width: '15%' },
				{ value: 'optOutLabelTranslation', text: 'Opt Out Label', width: '15%' },
				{ value: 'noChangeLabelTranslation', text: 'No Change Label', width: '15%', sortable: false }
			]
			// Filter out the Action column if user doesn't have full permissions as it will be empty and look weird
			if (this.userFullPermissions) {
				translationTableHeaders.push({ value: 'action', text: 'Action', width: '10%' })
			}
			return translationTableHeaders
		},
		multipleLanguagesEnabled () {
			return this.languages.length > 0
		},
		filteredOptionLabelTranslations () {
			const defaultLanguage = 0
			return this.lookupData.optionLabelTranslations?.filter(({ languageId }) => languageId !== defaultLanguage)
		}
	},
	async created () {
		await this.loadLanguages()
		const firstLanguage = this.tableRecords[0]
		if (firstLanguage) {
			this.defaultLanguage = firstLanguage.languageName.replace(' (Default)', '')
		}
	},
	methods: {
		editAssignedLanguage ({ enabledInPublicPortal, allowPrefPageAutoTranslation, languageLookupID, languageName, languageCode, languageID, brandID }) {
			this.isEdit = true
			this.assignedLanguage = {
				language: {
					languageLookupId: languageLookupID,
					languageName: languageName,
					googleLanguageCode: languageCode,
					languageCode: languageCode,
					brandID: brandID
				},
				enabledInPublicPortal: Boolean(enabledInPublicPortal),
				allowPrefPageAutoTranslation: Boolean(allowPrefPageAutoTranslation),
				languageID: languageID,
				languageName: languageName,
				languageCode: languageCode
			}
		},
		editOptionTranslations (optionLabelTranslation) {
			this.languageTranslationToEdit = optionLabelTranslation
		},
		async loadLanguages () {
			const DEFAULT_LANGUAGE = 0
			this.defaultLanguage = this.tableRecords.find(({ languageID }) => languageID === DEFAULT_LANGUAGE).languageName
		},

		addAssignedLanguage () {
			this.showAddModal = true
			this.isEdit = false
			this.assignedLanguage = {
				languageLookupId: null,
				enabledInPublicPortal: false,
				allowPrefPageAutoTranslation: false,
				languageName: null,
				languageCode: null
			}
		},
		async SubmitDefaultLanguageName () {
			await updateDefaultLanguageName(this.defaultLanguage.replace(' (Default)', ''))
			showSnackbar(`The Default Language is set to '${this.defaultLanguage}'`)
			this.loadLanguages()
		},
		clearFilters () {
			this.languageCodeSearchQuery = ''
		}
	}
}
</script>
