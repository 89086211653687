<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="searchQuery"
			search-query-label="Personal Information Field Name"
			hide-brand-filter
		/>
		<SectionCard>
			<template #title>
				Button Translations
			</template>
			<template #title-action>
				<Dropdown
					:value="selectedLanguageIdOrDefault"
					label="Language"
					:items="assignedLanguagesItems"
					:disabled="disableLanguageField"
					@input="changeLanguage"
				/>
			</template>
			<template #body>
				<DataTable
					:headers="buttonTranslationHeaders"
					:items="buttonTranslationItems"
					hide-default-footer
				>
					<template #item.translatedButtonText="{ item, index }">
						<TextField
							:label="`${item.buttonText} Translation`"
							:value="buttonField(index)"
							small
							class="my-2"
							@input="updateButtonTranslation($event, index)"
						/>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Personal Information Fields Translations
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="contactFieldTranslations"
					:items-per-page="50"
					hide-default-footer
				>
					<template #item.translatedFieldText="{ item, index }">
						<TextField
							:label="`${item.defaultDisplayName} Translation`"
							:value="piiField(index)"
							small
							class="my-2"
							@input="updatePiiTranslation($event, index)"
						/>
					</template>
				</DataTable>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import { format } from 'date-fns'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import { DATE_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { upsertPiiFieldTranslation, upsertLinkButtonTranslation } from '../../../../../../shared/utils/api/admin-portal/links/links.js'

export default {
	components: { SectionCard, DataTable, FilterBar, Dropdown, TextField },
	props: {
		userFullPermissions: Boolean,
		userReadOnly: Boolean,
		contactFields: Array,
		linksButtons: Object
	},
	data () {
		return {
			searchQuery: null,
			assignedLanguages: [],
			selectedLanguageId: null,
			piiFieldsToTranslate: [],
			buttonsToTranslate: [],
			disableLanguageField: false
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'ID', value: 'id', width: '10%' },
				{ text: 'Personal Information Field Name', value: 'defaultDisplayName', width: '20%' },
				{ text: 'Translated Personal Information Field Name', value: 'translatedFieldText', width: '35%' },
				{ text: 'Date Created', value: 'createdDate', width: '15%' }
			]
		},
		buttonTranslationHeaders () {
			return [
				{ text: 'ID', value: 'buttonId', width: '10%' },
				{ text: 'Default Button Text', value: 'buttonText', width: '19%' },
				{ text: 'Translated Button Text', value: 'translatedButtonText', width: '34%' },
				{ text: 'Date Created', value: 'createdDate', width: '15%' }
			]
		},
		selectedLanguageIdOrDefault () {
			if (this.selectedLanguageId !== null) return this.selectedLanguageId
			return this.assignedLanguagesItems[0]?.value
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		contactFieldTranslations () {
			return this.contactFields?.map(field => {
				const contactFieldTranslation = field.translations?.find(({ languageID }) => languageID === this.selectedLanguageIdOrDefault)
				const language = this.assignedLanguagesItems.find(({ value }) => value === this.selectedLanguageIdOrDefault)
				return {
					...field,
					contactFieldsTranslationId: contactFieldTranslation?.id,
					languageId: language?.value,
					languageName: language?.text,
					translatedFieldText: contactFieldTranslation?.translatedFieldText,
					createdDate: contactFieldTranslation?.createdDate && contactFieldTranslation?.translatedFieldText
						? format(new Date(contactFieldTranslation?.createdDate), DATE_FORMAT)
						: null
				}
			})
		},
		buttonTranslationItems () {
			return this.linksButtons.lookupData.map(button => {
				const linkButtonTranslation = this.linksButtons.translations?.find(({ languageId, buttonId }) =>
					languageId === this.selectedLanguageIdOrDefault && buttonId === button.buttonId)
				const language = this.assignedLanguagesItems.find(({ value }) => value === this.selectedLanguageIdOrDefault)
				return {
					...button,
					languageId: language?.value,
					languageName: language?.text,
					translatedButtonText: linkButtonTranslation?.translatedButtonText,
					createdDate: linkButtonTranslation?.createdDate && linkButtonTranslation?.translatedButtonText
						? format(new Date(linkButtonTranslation?.createdDate), DATE_FORMAT)
						: null
				}
			})
		}
	},
	async created () {
		await this.loadLanguages()
		await this.setLanguageToEdit()
	},
	methods: {
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		changeLanguage (languageId) {
			this.selectedLanguageId = languageId
			this.setLanguageToEdit()
		},
		piiField (index) {
			return this.piiFieldsToTranslate[index]?.translatedFieldText
		},
		buttonField (index) {
			return this.buttonsToTranslate[index]?.translatedButtonText
		},
		setLanguageToEdit () {
			this.piiFieldsToTranslate = this.contactFieldTranslations?.filter(({ languageId }) => languageId === this.selectedLanguageIdOrDefault)
			this.buttonsToTranslate = this.buttonTranslationItems?.filter(({ languageId }) => languageId === this.selectedLanguageIdOrDefault)
		},
		updatePiiTranslation (value, index) {
			this.$set(this.piiFieldsToTranslate, index, { ...this.piiFieldsToTranslate[index], translatedFieldText: value })
			this.$emit('general-translations-changed', true)
			this.disableLanguageField = true
		},
		updateButtonTranslation (value, index) {
			this.$set(this.buttonsToTranslate, index, { ...this.buttonsToTranslate[index], translatedButtonText: value })
			this.$emit('general-translations-changed', true)
			this.disableLanguageField = true
		},
		async saveTranslations () {
			this.disableLanguageField = false
			await this.savePiiTranslations()
			await this.saveButtonTranslations()
			showSnackbar('Translations saved successfully')
			this.$emit('general-translations-changed', false) /* reset save/cancel fields */
			this.$emit('load-links')
			this.$emit('load-links-buttons')
		},
		async savePiiTranslations () {
			const piiTranslations = this.piiFieldsToTranslate.map(({ contactFieldsTranslationId, translatedFieldText, languageId, id }) => ({
				contactFieldsTranslationId,
				contactFieldId: id,
				languageId,
				translatedFieldText
			}))
			await upsertPiiFieldTranslation({
				contactDetailsTranslations: piiTranslations
			})
		},
		async saveButtonTranslations () {
			const buttonTranslations = this.buttonsToTranslate.map(({ buttonId, translatedButtonText, languageId }) => ({
				buttonId,
				languageId,
				translatedButtonText
			}))
			await upsertLinkButtonTranslation({
				linkButtonTranslations: buttonTranslations
			})
		},
		cancelTranslationChanges () {
			this.piiFieldsToTranslate = this.contactFieldTranslations.filter(({ languageId }) => languageId === this.selectedLanguageIdOrDefault)
			this.buttonsToTranslate = this.buttonTranslationItems.filter(({ languageId }) => languageId === this.selectedLanguageIdOrDefault)
			this.$emit('general-translations-changed', false)
			this.disableLanguageField = false
		}
	}
}
</script>
