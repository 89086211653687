<template>
	<ViewLayout>
		<template #header-title>
			Languages
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<LanguagesTab
						v-if="assignedLanguages.length > 0"
						:table-records="assignedLanguages"
						:user-full-permissions="userFullPermissions"
						:language-list="languageList"
						:languages="languages"
						@load-languages="loadLanguages"
					/>
				</template>
				<template #1>
					<OptionLabelTranslationsTab
						:table-headers="optionLabelTranslationsTableHeaders"
						:option-label-translations="filteredOptionLabelTranslations"
						:multiple-languages-enabled="multipleLanguagesEnabled"
						:user-full-permissions="userFullPermissions"
						@load-option-labels="loadOptionLables"
					/>
				</template>
				<template #2>
					<GeneralTranslationsTab
						ref="generalTranslationsTab"
						:user-full-permissions="userFullPermissions"
						:user-read-only="userReadOnly"
						:contact-fields="lookupLinksData.contactFields"
						:links-buttons="linksButtons"
						@load-links-buttons="loadLinksButtonTranslations"
						@load-links="loadLinks"
						@general-translations-changed="generalTranslationsChanged"
					/>
				</template>
			</TabItems>
		</Template>
		<template
			v-if="tab === '2' && userFullPermissions"
			#footer
		>
			<PageActionRow>
				<template #actions>
					<v-spacer />
					<SecondaryActionButton
						:disabled="!changeMadeToGeneralTranslations"
						@click="cancelGeneralTranslationChanges"
					>
						Cancel
					</SecondaryActionButton>
					<PrimaryActionButton
						class="ml-2"
						:disabled="!changeMadeToGeneralTranslations"
						@click="saveGeneralTranslations"
					>
						Save
					</PrimaryActionButton>
				</template>
			</PageActionRow>
		</template>
	</ViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { LANGUAGES_MODULE_FULL_PERMISSIONS, SEE_LINKS_AREA } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { getAssignedLanguages, getLanguages, createLanguage, updateLanguage, updateDefaultLanguageName, getLanguageTabTranslations } from '../../../../../../shared/utils/api/languages.js'
import OptionLabelTranslationsTab from './option-label-translations-tab.vue'
import LanguagesTab from './languages-tab.vue'
import GeneralTranslationsTab from './general-translations-tab.vue'
import { getLinks, getLinkButtonTranslations } from '../../../../../../shared/utils/api/admin-portal/links/links.js'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'

export default {
	components: {
		Tabs,
		TabItems,
		ViewLayout,
		OptionLabelTranslationsTab,
		LanguagesTab,
		GeneralTranslationsTab,
		SecondaryActionButton,
		PrimaryActionButton,
		PageActionRow
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			searchQuery: null,
			languageCodeSearchQuery: null,
			showAddModal: false,
			assignedLanguage: null,
			languages: [],
			assignedLanguages: [],
			languageList: [],
			defaultLanguage: '',
			selectedLanguageName: '',
			isEdit: false,
			tabs: [{ title: 'Languages', slot: '0' }, { title: 'Label Translations', slot: '1' }, { title: 'General Translations', slot: '2' }],
			tab: '0',
			optionLabelTranslations: [],
			lookupData: [],
			lookupLinksData: [],
			linksButtons: null,
			changeMadeToGeneralTranslations: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		calculateSuggestions () {
			return this.languages.find(({ languageLookupId }) => languageLookupId === this.assignedLanguage.languageLookupId)
				?.googleLanguageCode ?? ''
		},
		usedLanguageCodes () {
			return this.assignedLanguages
				.filter(({ languageID }) => languageID !== this.assignedLanguage?.languageID)
				.map(({ languageCode }) => languageCode)
		},
		readOnlyPermissions () {
			return !this.productAreaPermission(LANGUAGES_MODULE_FULL_PERMISSIONS)
		},
		userFullPermissions () {
			return this.productAreaPermission(LANGUAGES_MODULE_FULL_PERMISSIONS)
		},
		optionLabelTranslationsTableHeaders () {
			const translationTableHeaders = [
				{ value: 'languageName', text: 'Language', width: '15%' },
				{ value: 'isTranslated', text: 'Translated Status', width: '15%' },
				{ value: 'optInTranslation', text: 'Opt In Label', width: '15%' },
				{ value: 'optOutTranslation', text: 'Opt Out Label', width: '15%' },
				{ value: 'noChangeTranslation', text: 'No Change Label', width: '15%', sortable: false }
			]
			// Filter out the Action column if user doesn't have full permissions as it will be empty and look weird
			if (this.userFullPermissions) {
				translationTableHeaders.push({ value: 'action', text: 'Action', width: '10%' })
			}
			return translationTableHeaders
		},
		multipleLanguagesEnabled () {
			return this.languages.length > 0
		},
		filteredOptionLabelTranslations () {
			const defaultLanguage = this.lookupData.find(({ languageID }) => languageID === 0)
			return [
				defaultLanguage,
				...this.lookupData.filter(({ languageID }) => languageID !== 0).sort((a, b) => a.languageName.localeCompare(b.languageName))
			]
		},
		userReadOnly () {
			return this.productAreaPermission(SEE_LINKS_AREA)
		}
	},
	async created () {
		await this.loadLanguages()
		const firstLanguage = this.assignedLanguages[0]
		if (firstLanguage) {
			this.defaultLanguage = firstLanguage.languageName.replace(' (Default)', '')
		}
		this.loadOptionLables()
		this.languages = this.assignedLanguages
		this.loadLinks()
		this.loadLinksButtonTranslations()
	},
	methods: {
		async loadOptionLables () {
			const { data: lookupLanguageData } = await getLanguageTabTranslations()
			this.lookupData = lookupLanguageData
		},
		editAssignedLanguage ({ enabledInPublicPortal, allowPrefPageAutoTranslation, languageLookupID, languageName, languageCode, languageID, brandID }) {
			this.isEdit = true
			this.assignedLanguage = {
				language: {
					languageLookupId: languageLookupID,
					languageName: languageName,
					googleLanguageCode: languageCode,
					languageCode: languageCode,
					brandID: brandID
				},
				enabledInPublicPortal: Boolean(enabledInPublicPortal),
				allowPrefPageAutoTranslation: Boolean(allowPrefPageAutoTranslation),
				languageID: languageID,
				languageName: languageName,
				languageCode: languageCode
			}
		},
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			const { data: languages } = await getLanguages()
			this.assignedLanguages = assignedLanguages
			this.languages = languages
			this.languageList = languages
			const DEFAULT_LANGUAGE = 0
			this.defaultLanguage = this.assignedLanguages.find(({ languageID }) => languageID === DEFAULT_LANGUAGE).languageName
		},
		addAssignedLanguage () {
			this.showAddModal = true
			this.isEdit = false
			this.assignedLanguage = {
				languageLookupId: null,
				enabledInPublicPortal: false,
				allowPrefPageAutoTranslation: false,
				languageName: null,
				languageCode: null
			}
		},
		closeSubmitModal () {
			this.showAddModal = false
			this.assignedLanguage = null
		},
		tableData () {
			return this.assignedLanguages.filter(({ languageName, languageCode }) => {
				let check = true
				if (this.searchQuery) check = languageName.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.languageCodeSearchQuery) check = languageCode?.toLowerCase().includes(this.languageCodeSearchQuery.toLowerCase())
				return check
			})
		},
		async submit () {
			const {
				allowPrefPageAutoTranslation, enabledInPublicPortal,
				languageCode, languageLookupId, languageID, languageName
			} = this.assignedLanguage

			if (this.usedLanguageCodes.includes(languageCode)) {
				showSnackbar({ text: `Language code '${languageCode} already exists.`, color: 'error' })
				return
			}
			if (this.isEdit) {
				await updateLanguage(languageID,
					languageName.replace(' (Default)', ''),
					languageCode,
					enabledInPublicPortal,
					allowPrefPageAutoTranslation)
				if (languageID === 0) {
					// Reload Default Language
					this.defaultLanguage = languageName
				}
			} else {
				await createLanguage(languageLookupId,
					languageName,
					languageCode,
					enabledInPublicPortal,
					allowPrefPageAutoTranslation)
			}
			showSnackbar(`${languageName} has been ${this.showAddModal ? 'created' : 'updated'}`)
			this.closeSubmitModal()
			this.loadLanguages()
		},
		async SubmitDefaultLanguageName () {
			await updateDefaultLanguageName(this.defaultLanguage.replace(' (Default)', ''))
			showSnackbar(`The Default Language is set to '${this.defaultLanguage}'`)
			this.loadLanguages()
		},
		clearFilters () {
			this.languageCodeSearchQuery = ''
		},
		generalTranslationsChanged (changeMade) {
			this.changeMadeToGeneralTranslations = changeMade
		},
		saveGeneralTranslations () {
			this.$refs.generalTranslationsTab.saveTranslations()
		},
		cancelGeneralTranslationChanges () {
			this.$refs.generalTranslationsTab.cancelTranslationChanges()
		},
		async loadLinks () {
			const mapAuthenticationType = links => links.map(link => ({ ...link, authenticationType: link.authenticationDetails?.authTypeDescription }))
			const { data: { actionLinks, preferencePageLinks, lookUpData } } = await getLinks()
			this.lookupLinksData = lookUpData
			this.actionLinks = mapAuthenticationType(actionLinks)
			this.preferencePageLinks = mapAuthenticationType(preferencePageLinks.map(preferencePageLink => ({
				...preferencePageLink,
				preferencePageLinkConfig: {
					extendedPrefs: preferencePageLink.preferencePageLinkConfig.extendedPrefs,
					internalPageDescription: preferencePageLink.preferencePageLinkConfig.internalPageDescription,
					pageContents: preferencePageLink.preferencePageLinkConfig.pageContents,
					pagePreferences: preferencePageLink.preferencePageLinkConfig.pagePreferences.sort((a, b) => a.displayOrderInPreferenceArea - b.displayOrderInPreferenceArea),
					pageSettings: preferencePageLink.preferencePageLinkConfig.pageSettings,
					preferencePageId: preferencePageLink.preferencePageLinkConfig.preferencePageId,
					showChannelName: preferencePageLink.preferencePageLinkConfig.showChannelName
				}
			})))
		},
		async loadLinksButtonTranslations () {
			const linksButtons = await getLinkButtonTranslations()
			this.linksButtons = linksButtons.data
		}
	}
}
</script>
