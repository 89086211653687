<template>
	<div class="cassie-vertical-md mt-1">
		<SectionCard>
			<template #title>
				Option Label Translations
			</template>
			<template #body>
				<DataTable
					:items="optionLabelTranslations"
					:headers="tableHeaders"
					@click:row="editOptionLabelTranslations"
				>
					<template #item.isTranslated="{ item }">
						{{ labelsTranslated(item) ? 'Translated' : 'Untranslated' }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userFullPermissions"
							tooltip-text="Edit Translation"
							@click="editOptionLabelTranslations(item)"
						>
							mdi-pencil
						</IconButton>
					</template>
				</DataTable>
				<ManageOptionTranslationModal
					v-if="optionLabelTranslationToEdit"
					:option-translation-to-edit="optionLabelTranslationToEdit"
					:user-full-permissions="userFullPermissions"
					@close="optionLabelTranslationToEdit = null"
					@saved="$emit('load-option-labels')"
				/>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ManageOptionTranslationModal from './manage-option-translation-modal.vue'

export default {
	components: {
		DataTable,
		SectionCard,
		ManageOptionTranslationModal,
		IconButton
	},
	props: {
		optionLabelTranslations: {
			type: Array,
			default: () => []
		},
		tableHeaders: {
			type: Array,
			default: () => []
		},
		multipleLanguagesEnabled: Boolean,
		userFullPermissions: Boolean
	},
	data () {
		return {
			optionLabelTranslationToEdit: null
		}
	},
	methods: {
		labelsTranslated (labelTranslation) {
			return Boolean(labelTranslation.optInTranslation || labelTranslation.optOutTranslation || labelTranslation.noChangeTranslation)
		},
		editOptionLabelTranslations (optionLabelTranslation) {
			this.optionLabelTranslationToEdit = optionLabelTranslation
		}
	}
}
</script>
