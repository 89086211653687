var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cassie-vertical-md mt-1"},[_c('FilterBar',{attrs:{"search-query":_vm.searchQuery,"search-query-label":"Language Name","hide-brand-filter":""},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event},"clearFilters":_vm.clearFilters},scopedSlots:_vm._u([{key:"after-filters",fn:function(){return [_c('TextField',{attrs:{"label":"Language Code","append-icon":"mdi-magnify"},model:{value:(_vm.languageCodeSearchQuery),callback:function ($$v) {_vm.languageCodeSearchQuery=$$v},expression:"languageCodeSearchQuery"}})]},proxy:true},{key:"action",fn:function(){return [(!_vm.readOnlyPermissions)?_c('PrimaryActionButton',{on:{"click":_vm.addAssignedLanguage}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create ")],1):_vm._e()]},proxy:true}])}),_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Default Language ")]},proxy:true},{key:"body",fn:function(){return [_c('ValidationForm',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"d-flex cassie-horizontal-md"},[_c('TextField',{staticClass:"cassie-input-width-md",attrs:{"disabled":_vm.readOnlyPermissions,"label":"Default Language Name *","rules":"required"},model:{value:(_vm.defaultLanguage),callback:function ($$v) {_vm.defaultLanguage=$$v},expression:"defaultLanguage"}}),(!_vm.readOnlyPermissions)?_c('PrimaryActionButton',{on:{"click":function($event){return handleSubmit(_vm.SubmitDefaultLanguageName)}}},[_vm._v(" Save ")]):_vm._e()],1)]}}])})]},proxy:true}])}),_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Additional Languages ")]},proxy:true},{key:"body",fn:function(){return [_c('DataTable',{attrs:{"items":_vm.tableData,"headers":_vm.tableHeaders},on:{"click:row":_vm.editAssignedLanguage},scopedSlots:_vm._u([{key:"item.enabledInPublicPortal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.enabledInPublicPortal ? 'Yes' : 'No')+" ")]}},{key:"item.allowPrefPageAutoTranslation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.allowPrefPageAutoTranslation ? 'Yes' : 'No')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('IconButton',{attrs:{"tooltip-text":"Open Assigned Language"},on:{"click":function($event){return _vm.editAssignedLanguage(item)}}},[_vm._v(" "+_vm._s(_vm.readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil')+" ")])]}}])})]},proxy:true}])}),(_vm.assignedLanguage)?_c('ManageLanguageModal',{attrs:{"option-translation-to-edit":_vm.assignedLanguage,"user-full-permissions":_vm.readOnlyPermissions,"languages":_vm.languageList,"is-edit":_vm.isEdit},on:{"close":function($event){_vm.assignedLanguage = null},"saved":function($event){return _vm.$emit('load-languages')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }