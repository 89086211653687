<template>
	<validation-observer #default="{ handleSubmit }">
		<Modal width="500px">
			<template #modal-title>
				Edit Translation
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<TextField
						:value="optionTranslation.languageName"
						view-only
						label="Language"
					/>
					<TextField
						v-model="optionTranslation.optInTranslation"
						:view-only="!userFullPermissions"
						label="Translated Opt In Label *"
						rules="required"
					/>
					<TextField
						v-model="optionTranslation.optOutTranslation"
						:view-only="!userFullPermissions"
						label="Translated Opt Out Label *"
						rules="required"
					/>
					<TextField
						v-model="optionTranslation.noChangeTranslation"
						:view-only="!userFullPermissions"
						label="Translated No Change Label *"
						rules="required"
					/>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="close">
					{{ userFullPermissions ? 'Cancel' : 'Close' }}
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="handleSubmit(save)"
				>
					Save
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import Modal from '../../../../../../shared/components/modal.vue'
import { updateOptionLabelTranslation } from '../../../../../../shared/utils/api/languages.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import TextField from '../../../../../../shared/components/text-field.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
export default {
	components: { PrimaryActionButton, SecondaryActionButton, TextField, Modal },
	props: {
		optionTranslationToEdit: Object,
		userFullPermissions: Boolean
	},
	data () {
		return {
			optionTranslation: JSON.parse(JSON.stringify(this.optionTranslationToEdit))
		}
	},
	methods: {
		async save () {
			const translationPayload = {
				languageId: this.optionTranslation.languageID,
				optInLabelTranslation: this.optionTranslation.optInTranslation,
				optOutLabelTranslation: this.optionTranslation.optOutTranslation,
				noChangeLabelTranslation: this.optionTranslation.noChangeTranslation
			}
			await updateOptionLabelTranslation(translationPayload)
			showSnackbar('This Translation has been updated')
			this.$emit('saved')
			this.close()
		},
		close () {
			this.$emit('close')
		}
	}
}
</script>
